import { capitalizeFirstWordAndFormat, titleCase, trimAndAppend } from './stringUtils';

export const getAlertDetails = (job, placeholder, number) => {
  if (!job || !job.alertDetails) {
    return '';
  }

  let formattedDetails;
  if (job.alertType === 'FSA') {
    formattedDetails = capitalizeFirstWordAndFormat(job.alertDetails);
  } else {
    formattedDetails = titleCase(job.alertDetails);
  }

  if (placeholder) {
    if (job.alertType === 'FSA') {
      return trimAndAppend(formattedDetails, number + 5);
    } else {
      return trimAndAppend(formattedDetails, number);
    }
  } else {
    return formattedDetails;
  }
};
