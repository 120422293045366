// src/services/customerService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';
import { getRoleAndSiteCode } from '../utils/roleUtils';

const getCustomerAlerts = async (page, pageSize, filterParams, sortingParams) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    paginationParams: {
      page,
      pageSize,
      filterRequest: {
        filterParams,
        filter: filterParams.length > 0 // Set filter to true if there are filters
      },
      sortingParams: sortingParams
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const customerAlertsURL = `${process.env.REACT_APP_API_BASE_URL_V2}${apiEndpoints.CUSTOMERLIST}`;
  const response = await axiosInstance.post(customerAlertsURL, payload);
  return response.data;
};

const getFilterValues = async () => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    role,
    siteCode
  };

  const filterValuesURL = `${process.env.REACT_APP_API_BASE_URL_V2}${apiEndpoints.CUSTOMERFILTERVALUES}`;
  const response = await axiosInstance.post(filterValuesURL, payload);
  return response.data;
};

const getCustomerAlertDetail = async (alertId, vin) => {
  const payload = {
    alertId,
    vin
  };
  const customerDetailURL = `${process.env.REACT_APP_API_BASE_URL_V2}${apiEndpoints.CUSTOMERDETAIL}`;
  const response = await axiosInstance.post(customerDetailURL, payload);
  return response.data;
};

const getCustomerSuggestions = async (searchParam, filterParams) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    searchParam,
    filterRequest: {
      filterParams,
      filter: filterParams.length > 0
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const searchURL = `${process.env.REACT_APP_API_BASE_URL_V2}${apiEndpoints.CUSTOMERSEARCH}`;
  const response = await axiosInstance.post(searchURL, payload);
  return response.data;
};

const exportCustomers = async (page, pageSize, filterParams, sortingParams) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    paginationParams: {
      page,
      pageSize,
      filterRequest: {
        filterParams,
        filter: filterParams.length > 0 // Set filter to true if there are filters
      },
      sortingParams: sortingParams
    },
    appUserDetails: {
      role,
      siteCode,
    },
  };

  const customerExportURL = `${process.env.REACT_APP_API_BASE_URL_V2}${apiEndpoints.CUSTOMEREXPORT}`;
  const response = await axiosInstance.post(customerExportURL, payload);
  return response.data;
};

const customerService = {
  getCustomerAlerts,
  getFilterValues,
  getCustomerAlertDetail,
  getCustomerSuggestions,
  exportCustomers
};

export default customerService;
