const updateNullValuesToNA = (obj, value) => {
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === '') {
        obj[key] = value;
      } else if (typeof obj[key] === 'object') {
        updateNullValuesToNA(obj[key]);
      }
    }
  }
  return obj;
};

export { updateNullValuesToNA };
