// src/containers/IssueDetail/IssueDetailHeader/IssueDetailHeaderStyles.js

export const styles = {
  mainBox: (headingStyle) => ({
    ...headingStyle
  }),
  titleContainer: {
    display: 'flex',
    gap: '8px',
    marginTop: '8px'
  },
  titleTypography: {
    fontSize: '36px',
    fontWeight: '600',
    lineHeight: '44px'
  },
  subtitleTypography: {
    marginTop: '-2px',
    fontWeight: '800',
    fontSize: '1rem',
    wordBreak: 'break-word'
  },
  closeButton: {
    marginRight: '-30px'
  }
};
