import React from 'react';
import { Snackbar, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomSnackbar = ({ open, onClose, title, description }) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={null} // Set to null if you don't want it to auto-hide
      sx={{
        width: '90%'}}
    >
      <Alert
        onClose={onClose}
        severity="null"
        sx={{
          width: '100%',
          backgroundColor: '#000000', // Black background
          color: '#FFFFFF', // White text
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <strong>{title}</strong>
        <div>{description}</div>
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;