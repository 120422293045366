import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Chip, Grid, Select, MenuItem, Snackbar, Alert } from '@mui/material';
import moment from 'moment';
import customerService from '../../services/customerService';
import issueService from '../../services/issueService';
import PriorityBadge from '../../components/PriorityBadge';
import { capitalize } from '../../utils/capitalize';
import { getAlertDetails } from '../../utils/getAlertDetails';
import { KeyboardArrowDown, KeyboardArrowUp, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { updateNullValuesToNA } from '../../utils/checkForNull';
import { useRole } from '../../context/RoleContext';
import { hasPageAccess, hasActionAccess } from '../../utils/permissions';
import ErrorPage from '../../components/ErrorPage';
import FilterSection from './FilterSection';
import { truncate } from '../../utils/truncate';
import IssuesDrawer from "../IssueDetail/Drawer";
import CustomPagination from '../../components/CustomPagination';
import CustomSnackbar from './CustomSnackbar';
import CustomerSearch from './CustomerSearch';
import CustomerExport from './CustomerExport';
const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    customerCategory: [],
    priority: [],
    alertType: [],
    alertStatus: []
  });
  const columnHeaders = [
    { label: 'Customer', width: '20%' },
    { label: 'VIN', width: '10%' },
    { label: 'Alerts', width: '30%' },
    { label: 'Priority', width: '15%' },
    { label: 'Created On', width: '15%' },
    { label: 'Alert Status', width: '25%' }
  ];
  const [customerDetails, setCustomerDetails] = useState({});
  const [state, setState] = useState({ drawerOpen: false, isIssuesLoaded: false, statusUpdatePopUp:false, snackbarMessage:'', autoHideStatus: null, snackbarSeverity: 'success', originalStatus: '' }); 
  const [isApiError, setIsApiError] = useState(null);
  const [customSnackbarOpen, setCustomSnackbarOpen] = useState(() => {
    const isSnackbarShown = sessionStorage.getItem('snackbarShown');
    return isSnackbarShown ? JSON.parse(isSnackbarShown) : true;
  });
  const [sortingParams, setSortingParams] = useState({
    sortingKey: '',
    direction: '',
  });

  const { roleConfig } = useRole();

  const fetchCustomerAlerts = useCallback(async (page, pageSize) => {
    try {
      setLoading(true);
      setCustomers([]);
      setTotalItems(0);
      const filterParams = Object.entries(selectedFilters)
        .filter(([_, values]) => values.length > 0)
        .map(([filterKey, filterValues]) => ({ filterKey, filterValues }));

      const sortingKeys = {
        priority: '',
        alertDatetime: '',
        status: ''
      };

      const sortingPayload = sortingParams.sortingKey ? {
        defaultSort: sortingParams.direction === '',
        sortingKey: {
          ...sortingKeys,
          [sortingParams.sortingKey]: sortingParams.direction,
        },
      } : {
        defaultSort: true,
        sortingKey: sortingKeys,
      };

      const response = await customerService.getCustomerAlerts(page + 1, pageSize, filterParams, sortingPayload);

      const totalItems = response.data.pagination.totalItems;
      const totalPages = Math.ceil(totalItems / pageSize);

      if (page >= totalPages) {
        setPage(0);
      } else {
        setCustomers(response.data.customerBasedAlerts);
        setTotalItems(totalItems);
      }
    } catch (error) {
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response.status,
      });
    } finally {
      setLoading(false);
    }
  }, [selectedFilters, setPage, sortingParams]);

  useEffect(() => {
    fetchCustomerAlerts(page, rowsPerPage);
  }, [page, rowsPerPage, selectedFilters, fetchCustomerAlerts, sortingParams]);

  useEffect(() => {
    const fetchFilterValues = async () => {
      try {
        const response = await customerService.getFilterValues();
        setFilterValues(response.data);
        setSelectedFilters(prev => ({
          ...prev,
          alertStatus: response.data.activeAlertStatus || []
        }));
      } catch (error) {
        console.error('Failed to fetch filter values', error);
        setIsApiError({
          isError: true,
          message: error.message,
          code: error.response.status
        });
      }
    };

    fetchFilterValues();
  }, []);

  const handleCustomSnackbarClose = () => {
    setCustomSnackbarOpen(false);
    // Set the flag in session storage after the snackbar is closed
    sessionStorage.setItem('snackbarShown', 'false');
  };

  const handleSortChange = useCallback((headerLabel) => {
    const keyMap = {
      'Priority': 'priority',
      'Created On': 'alertDatetime',
      'Alert Status': 'status'
    };

    const key = keyMap[headerLabel];

    setSortingParams((prev) => {
      const isSameKey = prev.sortingKey === key;
      let newDirection;

      if (['Priority', 'Created On', 'Alert Status'].includes(headerLabel)) {
        if (!isSameKey || prev.direction === '') {
          newDirection = 'asc';
        } else if (prev.direction === 'asc') {
          newDirection = 'dsc';
        } else {
          newDirection = '';
        }
      } else {
        newDirection = isSameKey && prev.direction === 'asc' ? 'dsc' : 'asc';
      }

      return {
        sortingKey: key,
        direction: newDirection,
      };
    });
  }, []);

  const handlePageChange = useCallback((event, newPage) => setPage(newPage), []);
  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const toggleDropdown = useCallback((key) => setOpenDropdowns(prev => ({ ...prev, [key]: !prev[key] })), []);

  const handleStatusChange = useCallback(async (event, alertId, newStatus, originalStatus, alertTitle) => {
    if (!hasActionAccess(roleConfig, 'list_alert', 'status_update')) {
      alert('Permission Denied');
      return;
    }

    try {
      await issueService.updateStatusValue(alertId, newStatus);
      setCustomers(prevCustomers =>
        prevCustomers.map(customer => ({
          ...customer,
          alerts: customer.alerts.map(alert =>
            alert.id === alertId ? { ...alert, status: newStatus } : alert
          )
        }))
      );
      const isSpecialStatus =
      (["Completed", "Cancelled"].includes(newStatus) && !["Completed", "Cancelled"].includes(originalStatus)) ||
      (["Completed", "Cancelled"].includes(originalStatus) && !["Completed", "Cancelled"].includes(newStatus));
      const autoHideDuration = isSpecialStatus ? null : 3000;
      const statusUpdateText = isSpecialStatus ? `${alertTitle} Alert Status changed from <strong>${originalStatus}</strong> to <strong>${newStatus}</strong> successfully` : `Status changed from <strong>${originalStatus}</strong> to <strong>${newStatus}</strong> successfully`;
      setState((prev) => ({
        ...prev,
        isIssuesLoaded: false,
        statusUpdatePopUp: true,
        autoHideStatus: autoHideDuration,
        originalStatus: newStatus,
        snackbarSeverity: 'success',
        snackbarMessage: statusUpdateText,
      }));
    } catch (error) {
      console.error("Error updating status:", error);
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response.status
      });
      setState((prev) => ({
        ...prev,
        isIssuesLoaded: false,
        statusUpdatePopUp: true,
        autoHideStatus: autoHideDuration,
        originalStatus: newStatus,
        snackbarSeverity: 'error',
        snackbarMessage: 'Failed to Update Status',
      }));
    }
  }, [roleConfig]);

  const fetchCustomerDetails = (alertId, vin) => {
    customerService.getCustomerAlertDetail(alertId, vin)
      .then((customer) => {
        const updatedCustomerData = updateNullValuesToNA(customer.data, "");
        setCustomerDetails(updatedCustomerData);
        setState(prev => ({ ...prev, isIssuesLoaded: true }));
      })
      .catch((error) => {
        console.error(error);
        setState(prev => ({ ...prev, isIssuesLoaded: false }));
        setIsApiError({
          isError: true,
          message: error.message,
          code: error.response.status
        });
      });
  }

  const handleDetailModel = useCallback((alertId, vin) => {
    if (!hasActionAccess(roleConfig, 'list_alert', 'view_alert_detail')) {
      alert('Permission Denied');
      return;
    }
    fetchCustomerDetails(alertId, vin);
    setState(prev => ({ ...prev, drawerOpen: true }));
  }, [roleConfig]);

  const handleDrawerClose = useCallback(() => {
    setState(prev => ({ ...prev, drawerOpen: false, isIssuesLoaded: false }));
  }, []);

  const renderCustomerNoneCell = (customer, alert) => {
    return (
      <>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '20%' }}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Chip label={customer.customerCategory || 'N/A'} style={{ borderRadius: "3px", fontWeight: "400", height: "24px", width: "69px", background: "E5E5E5" }} />
            <Box title={customer.customerName.length > 16 ? capitalize(customer.customerName) : ''}>
              {truncate(capitalize(customer.customerName), 19)}
            </Box>
          </Box>
        </TableCell>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} title={customer?.vin?.length > 20 ? customer?.vin : ''} style={{ width: '10%' }}>
          {customer?.vin?.length > 20 ? customer?.vin.slice(0, 30) + "..." : customer?.vin}
        </TableCell>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '30%' }}>
          <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item>
                <Chip
                  style={{ visibility: "hidden", width: "80px" }}
                />
              </Grid>
              <Grid item>
                <Box sx={{ textAlign: "center" }}>
                  No Alerts
                </Box>
              </Grid>
            </Grid>
          </Box>
        </TableCell>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '15%' }}>
          <PriorityBadge priority={"None"} />
        </TableCell>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '15%' }}></TableCell>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '25%' }}>
        </TableCell>
      </>
    )
  }

  const renderCustomerCell = useCallback((customer, index, alert) => {
    if (index === 0) {
      return (
        <>
          <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '20%' }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Chip label={customer.customerCategory || 'N/A'} style={{ borderRadius: "3px", fontWeight: "400", height: "24px", width: "69px", background: "E5E5E5" }} />
              <Box title={customer.customerName.length > 16 ? capitalize(customer.customerName) : ''}>
                {truncate(capitalize(customer.customerName), 19)}
              </Box>
            </Box>
          </TableCell>
          <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} title={customer?.vin?.length > 20 ? customer?.vin : ''} style={{ width: '10%' }}>
            {customer?.vin?.length > 20 ? customer?.vin.slice(0, 30) + "..." : customer?.vin}
          </TableCell>
        </>
      );
    }
    return (
      <>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '20%' }} />
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '10%' }} />
      </>
    );
  }, [handleDetailModel]);

  const renderAlertCell = useCallback((alert, dropdownKey, customer) => {
    return (
      <>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} title={alert?.alertDetails?.length > 80 ? getAlertDetails(alert, false, 80) : undefined} style={{ width: '40%' }}>
          <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item>
                <Chip
                  label={alert?.alertType.includes("Prognostics") ? alert?.alertType.slice(0, 4).toUpperCase() : alert?.alertType}
                  style={{ textTransform: "uppercase", marginRight: 4, borderRadius: "3px", fontWeight: "400", height: "24px", width: "69px", background: "E5E5E5" }}
                />
              </Grid>
              <Grid item>
                <Box>
                  {getAlertDetails(alert, true, 80)}
                  {alert?.vehicleAlertStatus && alert?.vehicleAlertStatus !== "EXISTING_ALERT" && (
                    <span style={{ marginLeft: "5px" }}>
                      ({alert?.vehicleAlertStatus})
                    </span>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </TableCell>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '10%' }}>
          <PriorityBadge priority={alert.severity} />
        </TableCell>
        <TableCell onClick={() => handleDetailModel(alert?.id, customer?.vin)} style={{ width: '10%' }}>{alert?.alertDatetime && alert.alertDatetime !== "NA" ? moment(alert.alertDatetime).format("MM-DD-YYYY") : "NA"}</TableCell>
        <TableCell style={{ width: '20%' }}>
          {alert.status !== "None" && <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "4px", marginLeft: "-15px" }}>
            <Box>
              {
                openDropdowns[dropdownKey] ?
                  <KeyboardArrowUp sx={{ color: "#0562D2", fontWeight: "100" }} />
                  :
                  <KeyboardArrowDown sx={{ color: "#0562D2", fontWeight: "100" }} />
              }
            </Box>
            <Select
              value={alert.status}
              role='button'
              label="Status"
              onChange={(event) => handleStatusChange(event, alert.id, event.target.value, alert.status, alert.alertTitle )}
              data-testid={openDropdowns[dropdownKey] ? 'open-dropdown-test-key' : 'close-dropdown-test-key'}
              onOpen={() => toggleDropdown(dropdownKey)}
              onClose={() => toggleDropdown(dropdownKey)}
              sx={{
                marginLeft: '-15px',
                fontSize: '14px',
                fontWeight: 'bold',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                  outline: 'none'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  outline: 'none'
                }
              }}
              style={{
                color: "#0562D2", width: "150px", marginRight: "-20px"
              }}
              IconComponent={() => null}

            >
              {["Open", "Unreachable", "Contacted", "Scheduled", "In Progress", "On Hold", "Completed", "Cancelled"].map((status) => (
                <MenuItem
                  sx={{ fontWeight: "bold", fontSize: '14px' }}
                  style={{ color: "#0562D2" }}
                  key={status}
                  value={status}
                  data-testid={status}
                >
                  {status}
                </MenuItem>
              ))}
            </Select>
          </Box>}
        </TableCell>
      </>
    );
  }, [handleDetailModel, handleStatusChange, openDropdowns, toggleDropdown]);

  const handleVinSelect = (vin) => {
    setSelectedFilters((prev) => {
      const currentVins = prev.vin || [];
      if (!currentVins.includes(vin)) { // Check if VIN is already in the list
        return {
          ...prev,
          vin: [...currentVins, vin], // Append the new VIN
        };
      }
      return prev; // Return the previous state if VIN is already present
    });
  };

  const renderLoader = useMemo(() => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        position: 'fixed',
        top: "50%",
        left: "50%",
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 1000
      }}
    >
      <CircularProgress />
    </Box>
  ), []);

  if (isApiError) {
    return <ErrorPage isApiError={isApiError} />;
  }

  const reloadData = (issueId, vin) => {
    if(issueId){
      fetchCustomerDetails(issueId, vin);
    }
  }

  return (
    <Container data-testid="jobs-list-container" maxWidth={false}>
      <Box sx={{ padding: "30px 0" }}>
        <Typography variant="h5" sx={{ fontSize: '40px', fontWeight: 400, lineHeight: '42px' }}>
          <b>Customers</b> {totalItems > 0 && `(${totalItems})`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, marginBottom: '20px' }}>
      {hasActionAccess(roleConfig, 'list_customer', 'search_vin') && (
      <CustomerSearch onVinSelect={handleVinSelect} selectedFilters={selectedFilters} setIsApiError={setIsApiError} />
      )}
      {hasActionAccess(roleConfig, 'list_customer', 'export_customer') && (
        <CustomerExport totalItems={totalItems} selectedFilters={selectedFilters} sortingParams={sortingParams} setIsApiError={setIsApiError} />
      )}
      </Box>
      <FilterSection
        filterValues={filterValues}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />

      <TableContainer component={Paper}
        sx={{
          borderLeft: "none",
          borderRight: "none",
          boxShadow: "none",
          borderTop: "1px solid #808080"
        }}
      >
        {loading && renderLoader}

        <Table>
          <TableHead>
            <TableRow>
              {columnHeaders.map(({ label, width }) => {
                const isSortable = ['Priority', 'Created On', 'Alert Status'].includes(label);
                let sortingKey;

                if (label === 'Created On') {
                  sortingKey = 'alertDatetime';
                } else if (label === 'Alert Status') {
                  sortingKey = 'status';
                } else {
                  sortingKey = 'priority';
                }
                const isCurrentSortKey = sortingParams.sortingKey === sortingKey;
                const iconDirection = sortingParams.direction === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />;
                const iconColor = isCurrentSortKey && sortingParams.direction !== '' ? '#000000' : '#bfbfbf';

                return (
                  <TableCell
                    key={label}
                    sx={{ color: "#666", cursor: isSortable ? 'pointer' : 'default', width, fontSize: "14px", fontFamily: "ford f1 bold" }}
                    onClick={isSortable ? () => handleSortChange(label) : undefined}
                  >
                    {label}
                    {isSortable && (
                      <span style={{ marginLeft: '5px', fontSize: '20px', color: iconColor }}>
                        {isCurrentSortKey ? iconDirection : <ArrowDropDown style={{ color: '#bfbfbf' }} />}
                      </span>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading && customers.length === 0 && (
              <TableRow>
                <TableCell colSpan={columnHeaders.length} sx={{ padding: "20px", textAlign: "center" }}>
                  <Typography variant="body1" sx={{ color: "#666666" }}>
                    No customer alerts found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {customers.map((customer) => {
              if (customer.alerts.length === 0) {
                return (
                  <TableRow sx={{ cursor: "pointer" }} key={customer.vin}>
                    {renderCustomerNoneCell(customer, alert)}
                  </TableRow>
                );
              }
              return customer.alerts.map((alert, index) => {
                const dropdownKey = `${customer.vin}-${alert.id || index}`;
                return (
                  <TableRow sx={{ cursor: "pointer" }} key={dropdownKey}>
                    {renderCustomerCell(customer, index, alert)}
                    {renderAlertCell(alert, dropdownKey, customer)}
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
        {!loading && <CustomPagination
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />}
      </TableContainer>
      <Snackbar
        open={state.statusUpdatePopUp}
        autoHideDuration={state.autoHideStatus}
        onClose={() => setState(prevState => ({ ...prevState, statusUpdatePopUp: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          width: '400px',  // Fixed width
          '& .MuiAlert-root': {
            alignItems: 'flex-start',  // Align items to start for multiline text
          },
        }}
      >
        <Alert onClose={() => setState(prevState => ({ ...prevState, statusUpdatePopUp: false }))} severity={state.snackbarSeverity}
          sx={{
            width: '100%', // Ensure the alert takes the full width of the snackbar
            whiteSpace: 'pre-line', // Allow for multi-line text
            wordBreak: 'break-word', // Break long words onto the next line
          }}
          >
          <span dangerouslySetInnerHTML={{ __html: state.snackbarMessage }} />
        </Alert>
      </Snackbar>
      <IssuesDrawer
        state={state}
        setState={setState}
        handleDrawerClose={handleDrawerClose}
        issueDetail={customerDetails}
        handleStatusChange={handleStatusChange}
        setCustomers={setCustomers}
        reloadData={reloadData}
        isCustomers={true}
      />
      {hasPageAccess(roleConfig, 'bottom_message', 'read') ? (
      <CustomSnackbar
        open={customSnackbarOpen}
        onClose={handleCustomSnackbarClose}
        title="Important Update: The Alerts page will be discontinued on December 20th!"
        description="To improve your experience, we have migrated to a new vehicle alerts table on the homepage of the Vehicle Care Portal. Your data is safe! Contact vcare@ford.com if you have questions."
      />
      ) : null}
    </Container>
  );
};

export default CustomerList;