import React, { useCallback } from "react";
import { Box, Button } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import Chip from "../../../components/Chip";

const CustomerFilterChips = ({selectedFilters, setSelectedFilters, filterValues}) => {

    const clearFilters = useCallback(() => {
        setSelectedFilters({
          customerCategory: [],
          priority: [],
          alertType: [],
          alertStatus: []
        });
      }, [setSelectedFilters]);

      const generateStatusChips = (values) => {
        const isAllActive = filterValues.activeAlertStatus.every(value => values.includes(value));
        const isAllInactive = filterValues.inActiveAlertStatus.every(value => values.includes(value));
    
        const chips = [];
        if (isAllActive) {
          chips.push(
            <Chip
              key="all-active-alerts"
              value="All Active Alerts"
              onDelete={() => handleFilterRemove('alertStatus', 'allActive')}
            />
          );
        }
    
        if (isAllInactive) {
          chips.push(
            <Chip
              key="all-inactive-alerts"
              value="All Inactive Alerts"
              onDelete={() => handleFilterRemove('alertStatus', 'allInactive')}
            />
          );
        }
    
        values.forEach(value => {
          if ((filterValues.activeAlertStatus.includes(value) && isAllActive) ||
            (filterValues.inActiveAlertStatus.includes(value) && isAllInactive)) {
            return;
          }
          chips.push(
            <Chip
              key={`status-${value}`}
              value={`${value}`}
              onDelete={() => handleFilterRemove('alertStatus', value)}
            />
          );
        });
    
        return chips;
      };

      const hasSelectedFilters = useCallback(() => Object.values(selectedFilters).some(values => values.length > 0), [selectedFilters]);    
      const generateChipsForFilter = (filterKey, values) => {
        if (values.length === 0) return [];
    
        if (filterKey === 'alertStatus') {
          return generateStatusChips(values);
        }
        return values.map(value => (
          <Chip
            key={`${filterKey}-${value}`}
            value={`${value}`}
            onDelete={() => handleFilterRemove(filterKey, value)}
          />
        ));
      };
    
      const handleFilterRemove = (filterKey, filterValue) => {
        setSelectedFilters(prev => {
          if (filterKey === 'alertStatus') {
            let newValues;
            if (filterValue === 'allActive') {
              newValues = prev[filterKey].filter(value => !filterValues.activeAlertStatus.includes(value));
            } else if (filterValue === 'allInactive') {
              newValues = prev[filterKey].filter(value => !filterValues.inActiveAlertStatus.includes(value));
            } else {
              newValues = prev[filterKey].filter(value => value !== filterValue);
            }
            return { ...prev, [filterKey]: newValues };
          }
          return {
            ...prev,
            [filterKey]: prev[filterKey].filter(value => value !== filterValue)
          };
        });
      };

      const allChips = Object.entries(selectedFilters).flatMap(([filterKey, values]) => generateChipsForFilter(filterKey, values));

      return (
        <>
          <Box display="flex" flexWrap="wrap" sx={{ marginLeft: '4px', marginBottom: "15px", background: '#ffffff', borderRadius: '16px', height: '2rem', marginTop: '15px', border: '0', textTransform: "capitalize" }}>
            {allChips}
            {allChips.length >= 2 && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={clearFilters}
                style={{border: "none"}}
              >
                <CloseSharp style={{ color: "#0562D2" }} />
                <span style={{ marginLeft: '4px', color: "#0562D2", fontWeight: "700", lineHeight: "20px" }}>Clear All</span>
              </Button>
            )}
          </Box>
        </>
      )
}

export default CustomerFilterChips;