import React from "react";
import { Icon } from "@ford/ford-ui-components";

const Chip = ({ onDelete, value }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#e5e5e5',
                padding: '0px 15px',
                borderRadius: '50px',
                marginLeft: '6px'
            }}
        >
            <span style={{fontSize: "16px"}}>{value}</span>
            <span
                style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: '#ffffff',
                    border: '2px solid #ffffff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    marginLeft: "6px"
                }}
                onClick={onDelete}
                data-testid="close"
            >
                <Icon name="close" />
            </span>
        </div>
    )
}

export default Chip;