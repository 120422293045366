import React, { useEffect, useState } from 'react';
import StatusDonutChart from './charts/StatusDonutChart';
import issueService from '../../services/issueService';
import AlertTypeDonutChart from './charts/AlertTypeDonutChart';
import { Icon, Typography, SelectDropdown } from '@ford/ford-ui-components'; // Adjust the import path as necessary
import { getRoleAndSiteCode } from '../../utils/roleUtils';
import ErrorPage from '../../components/ErrorPage';

const Legend = ({ colorMapping }) => (
  <div style={{
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '12px',
  }}>
    {Object.entries(colorMapping).map(([key, color]) => (
      <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <div style={{ width: '15px', height: '15px', backgroundColor: color, marginRight: '5px' }}></div>
        <span>{key}</span>
      </div>
    ))}
  </div>
);

const Metrics = () => {
  const { role, siteCode } = getRoleAndSiteCode();

  const [alerts, setAlerts] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [loadingType, setLoadingType] = useState(true);
  const [siteCodes, setSiteCodes] = useState((role === 'RCAuser' || role === 'Remote Care Assistant' || siteCode !== "") ? [siteCode] : []);
  const [siteCodeOptions, setSiteCodeOptions] = useState([]);
  const [numberMetric, setNumberMetric] = useState({
    firstStatusChangeMetrics: {},
    alertCompletionMetrics: {}
  });
  const [isApiError, setIsApiError] = useState(null);

  useEffect(() => {
    fetchSiteCodes();
  }, []);

  useEffect(() => {
    fetchMetrics();
  }, [siteCodes]);

  const fetchSiteCodes = async () => {
    try {
      const siteCodeData = await issueService.getAllSiteCodes();
      const options = siteCodeData.data[1]?.siteCodeList.map((code) => ({
        label: code,
        value: code,
      }));
      setSiteCodeOptions(options || []);
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchMetrics = () => {
    setLoadingStatus(true);
    setLoadingType(true);

    issueService.getAllMetrics(siteCodes).then((d) => {
      setAlerts(d?.data);
      setLoadingStatus(false);
      setLoadingType(false);
      updateNumberMetrics(d?.data);
    }).catch(handleApiError);
  };

  const updateNumberMetrics = (data) => {
    if (Array.isArray(data.firstStatusChangeMetrics) && data.firstStatusChangeMetrics.length > 0) {
      const lastMetric = data.firstStatusChangeMetrics[data.firstStatusChangeMetrics.length - 1];
      setNumberMetric(prevState => ({
        ...prevState,
        firstStatusChangeMetrics: lastMetric
      }));
    } else {
      console.log("alerts.firstStatusChangeMetrics is not defined or is empty");
    }

    if (Array.isArray(data.alertCompletionMetrics) && data.alertCompletionMetrics.length > 0) {
      const lastMetric = data.alertCompletionMetrics[data.alertCompletionMetrics.length - 1];
      setNumberMetric(prevState => ({
        ...prevState,
        alertCompletionMetrics: lastMetric
      }));
    } else {
      console.log("alerts.alertCompletionMetrics is not defined or is empty");
    }
  };

  const handleApiError = (error) => {
    console.error('Error:', error);
    setIsApiError({
      isError: true,
      message: error.message,
      code: error.response.status
    });
  };

  const handleFilterChange = (value) => {
    if (!siteCodes.includes(value.value)) {
      setSiteCodes((prev) => [...prev, value.value]);
    }
  };

  const handleRemoveSiteCode = (code) => {
    setSiteCodes((prev) => prev.filter((siteCode) => siteCode !== code));
  };

  if (isApiError) {
    return (
      <ErrorPage isApiError={isApiError} />
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px' }}>
      {(role === "InternalAdmin" || role === "CarePlus Internal Admin" || siteCode === "") && (
        <SelectDropdown
          emptyMessage="No options found"
          onValueChange={handleFilterChange}
          options={siteCodeOptions}
          placeholder="Select Site Codes"
          showChevron
          className='metrics-dropdown'
        />
      )}
      {(role === "InternalAdmin" || role === "CarePlus Internal Admin" || siteCode === "") && (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px', marginTop: "12px" }}>
          {siteCodes.map((code) => (
            <div
              key={code}
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#e0e0e0',
                padding: '5px 10px',
                borderRadius: '16px',
              }}
            >
              <span>{code}</span>
              <span
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: 'white',
                  border: '2px solid white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginLeft: "6px"
                }}
                onClick={() => handleRemoveSiteCode(code)}
              >
                <Icon name="close" />
              </span>
            </div>
          ))}
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
        <MetricCard
          title="Active alerts by status"
          loading={loadingStatus}
          data={alerts}
          chartComponent={StatusDonutChart}
          totalAlerts={alerts?.totalActiveAlertsByStatus}
          colorMapping={{
            Open: '#1f77b4',
            Unreachable: '#FFC0CB',
            Contacted: '#d62728',
            Scheduled: '#FFFF00',
            'In Progress': '#ff7f0e',
            'On Hold': '#2ca02c',
          }}
        />
        <MetricCard
          title="Active alerts by type"
          loading={loadingType}
          data={alerts}
          chartComponent={AlertTypeDonutChart}
          totalAlerts={alerts?.totalActiveAlertsByAlertCategory}
          colorMapping={{
            VHA: '#1f77b4',
            Prognostics: '#ff7f0e',
            FSA: '#2ca02c',
          }}
        />
        <TimeMetricCard
          title="Average time of Open to first status change (last 30 days)"
          loading={loadingType}
          time={numberMetric?.firstStatusChangeMetrics?.averageTimeInHours}
        />
        <TimeMetricCard
          title="Average time to complete alert (last 30 days)"
          loading={loadingType}
          time={numberMetric?.alertCompletionMetrics?.averageTimeInHours}
        />
      </div>
    </div>
  );
};

const MetricCard = ({ title, loading, data, chartComponent: ChartComponent, totalAlerts, colorMapping }) => {
  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    width: '680px',
    height: '600px',
    zIndex: 0,
    marginTop: '10px',
  };

  const chartContainerStyle = {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '20px',
    width: '100%',
    height: '100%',
    zIndex: 9999,
  };

  const alertCountStyle = {
    position: 'absolute',
    bottom: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#333',
  };

  return (
    <div style={cardStyle}>
      <Typography displayColor="text-onlight-moderate-default" displayStyle="subtitle-regular">
        {title}
      </Typography>
      <Legend colorMapping={colorMapping} />
      <div style={chartContainerStyle}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Icon name="loading" />
          </div>
        ) : (
          <ChartComponent data={data} />
        )}
        {!loading && (
          <div style={alertCountStyle}>
            Total Active Alerts: {totalAlerts ?? 0}
          </div>
        )}
      </div>
    </div>
  );
};

const TimeMetricCard = ({ title, loading, time }) => {
  const cardStyle = {
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    width: '680px',
    height: '600px',
    zIndex: 0,
    marginTop: '10px',
  };

  const chartContainerStyle = {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '20px',
    width: '100%',
    height: '100%',
    zIndex: 9999,
  };

  return (
    <div style={cardStyle}>
      <div style={{ width: "450px" }}>
        <Typography displayColor="text-onlight-moderate-default" displayStyle="subtitle-regular">
          {title}
        </Typography>
      </div>
      <div style={{ ...chartContainerStyle, flexDirection: 'column' }}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Icon name="loading" />
          </div>
        ) : (
          <>
            <h1 style={{ fontSize: '64px', margin: '0', color: '#333' }}>
              {time === 0 ? 'N/A' : time}
            </h1>
            <h1>Hours</h1>
          </>
        )}
      </div>
    </div>
  );
};

export default Metrics;
