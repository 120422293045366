export const styles = {
  profileHeadingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  profileHeadingText: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px'
  },
  profileTimestamp: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    color: '#808080'
  },
  profileDataText: {
    marginTop: '5px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px'
  }
};
