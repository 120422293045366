export const styles = {
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingX: 6,
    paddingY: 2,
    lineHeight: 2,
    borderBottom: '0.5px solid lightgrey',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)'
  },
  subHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingX: 6,
    paddingY: 2,
    fontSize: '0.8rem'
  },
  info: {
    paddingX: 6,
    paddingY: 2
  },
  table: {
    border: 'none',
    borderCollapse: 'separate'
  },
  tabHighlight: {
    width: '170px',
    fontWeight: '600',
    textTransform: 'capitalize',
    justifyContent: 'flex-start',
    fontSize: '20px',
    color: 'inherit',
    '&.Mui-selected': {
      color: '#0562D2'
    }
  }
};
