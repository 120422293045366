import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import moment from 'moment';
import issueService from '../../services/issueService';
import ErrorPage from '../../components/ErrorPage';

import { Button, Typography } from '@ford/ford-ui-components';
import { EditOutlined } from '@mui/icons-material';
import { TextField, Snackbar, Alert } from '@mui/material';

const DEFAULT_NOTE_TEXT = "Add a personal touch to your customer experience by keeping notes about this customer.";

const CustomerNote = ({
    issue,
    canReadNotes,
    canWriteNotes,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [customerNotes, setCustomerNotes] = useState({});
    const [notes, setNotes] = useState('');
    const [defaultNoteText, setDefaultNoteText] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [customerUpdatedDate, setCustomerUpdatedDate] = useState('');
    const [isApiError, setIsApiError] = useState(null);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
        if (!isEditing) {
            setNotes(customerNotes.customerNotes || '');
            setHasChanges(false);
        }
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        setHasChanges(e.target.value !== customerNotes.customerNotes);
    }

    useEffect(() => {
        const getNotes = async () => {
            try {
                const response = await issueService.getCustomerNote(issue?.vehicleInfo?.vehicleProfileId);
                setCustomerNotes(response.data);
                setCustomerUpdatedDate(response.data.updatedAt);
                setNotes(response.data.customerNotes || '');
                setDefaultNoteText(DEFAULT_NOTE_TEXT);
            } catch (error) {
                console.error(error);
                setIsApiError({
                    isError: true,
                    message: error.message,
                    code: error.response ? error.response.status : null,
                });
            }
        }
        getNotes();
    }, [issue]);

    const handleSaveNote = async () => {
        setIsSaving(true);
        try {
            const noteData = {
                customerNotesId: issue?.vehicleInfo?.vehicleProfileId,
                customerNotes: notes
            };
            const response = await issueService.updateCustomerNote(noteData);
            setCustomerNotes(noteData);
            setCustomerUpdatedDate(response.data);
            setIsEditing(false);
            setSnackbarMessage('Note saved successfully!');
            setSnackbarSeverity('success');
        } catch (error) {
            console.error(error);
            setIsApiError({
                isError: true,
                message: error.message,
                code: error.response ? error.response.status : null,
            });
            setSnackbarMessage('Failed to save the note.');
            setSnackbarSeverity('error');
        } finally {
            setIsSaving(false);
            setSnackbarOpen(true);
        }
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    if (isApiError) {
        /* istanbul ignore next */
        return <ErrorPage isApiError={isApiError} />;
    }

    return (
        <>
            {canReadNotes && <div style={{ margin: "5px 50px 0 50px", padding: "15px" }}>
                <div className='customer-notes-heading-container'>
                    <div>
                        <Typography
                            displayStyle='subtitle-regular'
                            displayColor='text-onlight-moderate-default'
                            spanProps={{ className: "customer-notes-heading" }}
                        >
                            Customer Notes
                        </Typography>
                        {!isEditing && (
                            <div style={{ marginTop: "10px", width: "500px" }}>
                                <Typography
                                    displayColor="text-onlight-moderate-default"
                                    displayStyle="body-1-regular"
                                    spanProps={customerNotes.customerNotes ? { className: 'text-custom-color-default' } : { className: 'text-custom-color' }}
                                >
                                    {customerNotes.customerNotes ? customerNotes.customerNotes : defaultNoteText}
                                </Typography>
                            </div>
                        )}
                        {isEditing && (
                            <div style={{ width: "500px", marginTop: "10px" }} mt={2}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    value={notes}
                                    onChange={handleNotesChange}
                                    placeholder={DEFAULT_NOTE_TEXT}
                                    helperText={`${notes.length}/1000 characters`}
                                    inputProps={{ maxLength: 1000 }}
                                />
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem", gap: "1rem" }}>
                                    <Button
                                        variant="outlined"
                                        sx={{ ml: 1, color: "#808080" }}
                                        onClick={() => setIsEditing(false)}
                                        disabled={isSaving}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant={hasChanges ? "filled" : "outlined"}
                                        sx={{ ml: 1, color: "#808080" }}
                                        onClick={handleSaveNote}
                                        disabled={!hasChanges || isSaving}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {notes &&
                            <div style={{
                                fontSize: '12px',
                                fontWeight: '400',
                                lineHeight: '16px',
                                color: '#808080',
                                marginLeft: "10px"
                            }}>
                                {customerUpdatedDate ? `Updated on ${moment(customerUpdatedDate).format('MM-DD-YYYY')}` : ''}
                            </div>}
                        {canWriteNotes && <Button
                            className='customer-notes-edit'
                            variant='outlined'
                            onClick={handleEditClick}
                        >
                            <EditOutlined sx={{ color: "#0562D2" }} />
                        </Button>}
                    </div>
                </div>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>}
        </>
    );
};

CustomerNote.propTypes = {
    issue: PropTypes.object,
    canReadNotes: PropTypes.bool.isRequired,
    canWriteNotes: PropTypes.bool.isRequired,
}

export default CustomerNote;
