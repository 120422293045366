import React, { useCallback, useState } from 'react';
import { Box, Button, Menu, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CustomerFilterChips from './CustomerFilterChips/CustomerFilterChips';

const FilterSection = ({ filterValues, selectedFilters, setSelectedFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFilterKey, setCurrentFilterKey] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleFilterMenuOpen = (event, filterKey) => {
    setAnchorEl(event.currentTarget);
    setCurrentFilterKey(filterKey);
    setIsMenuOpen(true);
  };

  const handleFilterMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleFilterChange = useCallback((filterKey, value) => {
    setSelectedFilters(prev => {
      const currentIndex = prev[filterKey].indexOf(value);
      const newSelected = [...prev[filterKey]];

      if (currentIndex === -1) {
        newSelected.push(value);
      } else {
        newSelected.splice(currentIndex, 1);
      }

      return { ...prev, [filterKey]: newSelected };
    });
  }, [setSelectedFilters]);

  const handleAlertStatusChange = useCallback((groupKey, isChecked) => {
    const groupValues = filterValues[groupKey];
    setSelectedFilters(prev => {
      const newAlertStatus = isChecked
        ? [...prev.alertStatus, ...groupValues.filter(value => !prev.alertStatus.includes(value))]
        : prev.alertStatus.filter(value => !groupValues.includes(value));
      handleFilterMenuClose();
      return { ...prev, alertStatus: newAlertStatus };
    });
  }, [filterValues, setSelectedFilters]);

  const renderFilterDropdown = (label, filterKey, options) => {
    const isOpen = isMenuOpen && currentFilterKey === filterKey;
    let filterCount = selectedFilters[filterKey]?.length > 0 ? `(${selectedFilters[filterKey].length})` : '';
    let menuHeadLabel = label + ' ' + filterCount;
    return (
      <Box sx={{ m: 1 }}>
        <Button
          variant="outlined"
          onClick={(event) => handleFilterMenuOpen(event, filterKey)}
          endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          sx={{
            borderRadius: '50px',
            textTransform: 'capitalize',
            fontWeight: '700',
            border: "1px solid #000000"
          }}
        >
          {menuHeadLabel}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleFilterMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{
            borderRadius: '50px', // Reduce the border radius here
          }}
        >
          {options.map((option) => (
            <MenuItem
              sx={{
                width: "200px",
                padding: "8px",
                fontSize: "14px",
                color: "#333333",
                margin: 0,
              }}
              key={option}
              value={option}
              onClick={() => handleFilterChange(filterKey, option)}
            >
              <Checkbox
                checked={selectedFilters[filterKey].indexOf(option) > -1}
                sx={{
                  color: "#808080",
                  '&.Mui-checked': {
                    color: "#0562D2",
                  },
                  margin:"2px",
                  padding: "4px"
                }}
              />
              <ListItemText
                primary={option}
                primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }}
              />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  const renderAlertStatusDropdown = (label) => {
    const isOpen = isMenuOpen && currentFilterKey === 'alertStatus';
    const activeAlertStatus = filterValues.activeAlertStatus || [];
    const inActiveAlertStatus = filterValues.inActiveAlertStatus || [];

    return (
      <Box sx={{ m: 1 }}>
        <Button
          variant="outlined"
          onClick={(event) => handleFilterMenuOpen(event, 'alertStatus')}
          endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          sx={{
            borderRadius: '50px',
            textTransform: 'capitalize',
            fontWeight: "700",
            border: "1px solid #000000"
          }}
        >
          {`${label} (${selectedFilters['alertStatus'].length})`}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleFilterMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuItem
            sx={{
              width: "200px",
              padding: "8px",
              fontSize: "14px",
              color: "#333333",
              margin: 0,          
            }}
            onClick={() => handleAlertStatusChange('activeAlertStatus', !activeAlertStatus.every(value => selectedFilters.alertStatus.includes(value)))}>
            <Checkbox
              checked={activeAlertStatus.every(value => selectedFilters.alertStatus.includes(value))}
              sx={{
                color: "#808080",
                '&.Mui-checked': {
                  color: "#0562D2",
                },
                padding: "4px"
              }}
            />
            <ListItemText primary="All Active Alerts" primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }} />
          </MenuItem>
          {activeAlertStatus.map((option) => (
            <MenuItem
              sx={{
                width: "200px",
                padding: "8px",
                fontSize: "14px",
                color: "#333333",
                margin: 0,
                pl: 4,
              }}
              key={option} value={option} onClick={() => handleFilterChange('alertStatus', option)}>
              <Checkbox
                checked={selectedFilters.alertStatus.includes(option)}
                sx={{
                  color: "#808080",
                  '&.Mui-checked': {
                    color: "#0562D2",
                  },
                  padding: "4px"
                }}
              />
              <ListItemText primary={option} primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }} />
            </MenuItem>
          ))}
          <MenuItem
            sx={{
              width: "200px",
              padding: "8px",
              fontSize: "14px",
              color: "#333333",
              marginTop: "12px",
            }}
            onClick={() => handleAlertStatusChange('inActiveAlertStatus', !inActiveAlertStatus.every(value => selectedFilters.alertStatus.includes(value)))}>
            <Checkbox
              checked={inActiveAlertStatus.every(value => selectedFilters.alertStatus.includes(value))}
              sx={{
                color: "#808080",
                '&.Mui-checked': {
                  color: "#0562D2"
                },
                padding: "4px"
              }}
            />
            <ListItemText primary="All Inactive Alerts" primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }} />
          </MenuItem>
          {inActiveAlertStatus.map((option) => (
            <MenuItem
              sx={{
                width: "200px",
                padding: "8px",
                fontSize: "14px",
                color: "#333333",
                margin: 0,
                pl: 4,
              }}
              key={option} value={option} onClick={() => handleFilterChange('alertStatus', option)}>
              <Checkbox
                checked={selectedFilters.alertStatus.includes(option)}
                sx={{
                  color: "#808080",
                  '&.Mui-checked': {
                    color: "#0562D2"
                  },
                  padding: "4px"
                }}
              />
              <ListItemText
                primary={option}
                primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }}
              />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  return (
    <Box sx={{ marginLeft: "-10px" }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {renderFilterDropdown('Customers', 'customerCategory', filterValues.customerCategory || [])}
        {renderFilterDropdown('Priority', 'priority', filterValues.priority || [])}
        {renderFilterDropdown('Alert Type', 'alertType', filterValues.alertType || [])}
        {renderAlertStatusDropdown('Alert Status')}
      </Box>
      <CustomerFilterChips
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filterValues={filterValues}
      />
    </Box>
  );
};

export default FilterSection;