import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import { useRole } from '../../../context/RoleContext';
import { hasActionAccess, hasPageAccess } from '../../../utils/permissions';
import { titleCase } from '../../../utils/stringUtils';

//styling
import { styles } from "./IssueDetailprofile.style";
import CustomerNote from "../CustomerNote";

const IssueDetailProfile = ({ issue }) => {
  const { roleConfig } = useRole();
  const hasProfileVehiclePermission = hasActionAccess(roleConfig, 'alert_detail', 'profile_vehicle');

  const canReadNotes = hasPageAccess(roleConfig, 'customer_note', 'read');
  const canWriteNotes = hasPageAccess(roleConfig, 'customer_note', 'write');

  const engineOilPercentDisplay = issue?.connectedVehicleInfo?.engineoilPercent ?? '-';
  const displayEngineOilValue = engineOilPercentDisplay !== '-' ? `${engineOilPercentDisplay}%` : '-';

  const fuelOrChargeDisplay = issue?.connectedVehicleInfo?.fuelOrCharge ?? '-';
  const displayFuelOrCharge = fuelOrChargeDisplay !== '-' ? `${fuelOrChargeDisplay}% full` : '-';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mx: 'auto' }}>
      {/* Customer Section */}
      <div style={{ margin: "10px" }}>
        <div style={{ margin: "10px 50px" }}>
          <div style={styles.profileHeadingContainer}>
            <div style={styles.profileHeadingText}>
              Customer Information
            </div>
            <div style={styles.profileTimestamp}>
              {issue?.customerInfo?.lastUpdatedDate ? `Updated on ${moment(issue?.customerInfo?.lastUpdatedDate).format('MM-DD-YYYY')}` : 'last updated at timestamp not available'}
            </div>
          </div>
          <table style={{ margin: "20px 0" }}>
            <tr>
              <td style={{ width: "250px" }}>
                <p style={styles.profileTimestamp}>Phone</p>
                <p style={styles.profileDataText}>
                  {"-"}
                </p>
              </td>
              <td style={{ width: "250px" }}>
                <p style={styles.profileTimestamp}>Email</p>
                <p style={styles.profileDataText}>
                  {
                    (
                      issue?.customerInfo?.emailAddress === null ||
                      issue?.customerInfo?.emailAddress === "" ||
                      issue?.customerInfo?.emailAddress === undefined
                    ) ? "-" : issue.customerInfo.emailAddress.toLowerCase()
                  }
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>

      {/* Ford pass rewards */}
      <div style={{ margin: "5px 50px 0 50px", padding: "15px", background: "#FAFAFA" }}>
        <div>
          <div style={styles.profileHeadingContainer}>
            <div style={styles.profileHeadingText}>
              FordPass Rewards
            </div>
            <div style={styles.profileTimestamp}>
              {issue?.customerInfo?.lastUpdatedDate ? `Updated on ${moment(issue?.customerInfo?.lastUpdatedDate).format('MM-DD-YYYY')}` : 'last updated at timestamp not available'}
            </div>
          </div>
          <table style={{marginTop: "20px"}}>
            <tr>
              <td style={{ width: "250px" }}>
                <p style={styles.profileTimestamp}>Points</p>
                <p style={styles.profileDataText}>{issue?.customerInfo?.fordPassRewardsInfo?.fordPassRewardsPoints ?? '-'}</p>
              </td>
              <td style={{ width: "250px" }}>
                <p style={styles.profileTimestamp}>Dollar value</p>
                <p style={styles.profileDataText}>-</p>
              </td>
            </tr>
            <tr>
              <td style={{ width: "250px", paddingTop: "15px" }}>
                <p style={styles.profileTimestamp}>Expiry Date</p>
                <p style={styles.profileDataText}>
                  -
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>

      {/* Customer Notes */}
      <CustomerNote 
        issue={issue} 
        canReadNotes={canReadNotes}
        canWriteNotes={canWriteNotes}
      />

      {/* Vehicle Information */}
      {hasProfileVehiclePermission && (
        <div style={{ margin: "5px 50px 0 50px", padding: "15px", background: "#FAFAFA" }}>
          <div>
            <div style={styles.profileHeadingContainer}>
              <div style={styles.profileHeadingText}>
                Vehicle Information
              </div>
              <div style={styles.profileTimestamp}>
                {issue?.connectedVehicleInfo?.sourceDataUpdateTime ? `Updated on ${moment(issue?.connectedVehicleInfo?.sourceDataUpdateTime).format('MM-DD-YYYY')}` : '(last updated at timestamp not available)'}
              </div>
            </div>
            <table style={{ margin: "20px 0" }}>
              <tr style={{}}>
                <td style={{ width: "250px", wordBreak: "break-word", overflowWrap: "break-word" }}>
                  <p style={styles.profileTimestamp}>VIN</p>
                  <p style={styles.profileDataText}>{issue?.vehicleInfo?.vin || '-'}</p>
                </td>
                <td>
                  <p style={styles.profileTimestamp}>Fuel Level</p>
                  <p style={styles.profileDataText}>{displayFuelOrCharge}</p>
                </td>
              </tr>
              <tr>
                <td style={{ width: "250px", padding: "10px 0" }}>
                  <p style={styles.profileTimestamp}>Trim</p>
                  <p style={styles.profileDataText}>
                    {issue?.vehicleInfo?.vehicleTrim ? titleCase(issue?.vehicleInfo?.vehicleTrim) : '-'}
                  </p>
                </td>
                <td style={{ width: "250px", padding: "10px 0" }}>
                  <p style={styles.profileTimestamp}>Engine Oil Life</p>
                  <p style={styles.profileDataText}>
                    {displayEngineOilValue}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ width: "250px", padding: "10px 0" }}>
                  <p style={styles.profileTimestamp}>Color</p>
                  <p style={styles.profileDataText}>{issue?.vehicleInfo?.vehicleColor ? titleCase(issue?.vehicleInfo?.vehicleColor) : '-'}</p>
                </td>
                <td style={{ width: "250px", padding: "10px 0" }}>
                  <p style={styles.profileTimestamp}>Odometer</p>
                  <p style={styles.profileDataText}>
                    {issue?.connectedVehicleInfo?.odometer ? `${issue?.connectedVehicleInfo?.odometer} miles` : '-'}
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}
    </Box>
  );
};

IssueDetailProfile.propTypes = {
  issue: PropTypes.object,
};

export default IssueDetailProfile;