// src/utils/roleUtils.js

import { getToken, parseJwt } from './auth';
import { getImpersonateData } from './impersonateUtils';

export const getRoleAndSiteCode = () => {
  const token = getToken();
  if (!token) {
    console.error('Token is not available');
    return { role: '', siteCode: '' }; // Default values if token is missing
  }

  const parsedToken = parseJwt(token);
  if (!parsedToken) {
    console.error('Failed to parse token');
    return { role: '', siteCode: '' }; // Default values if parsing fails
  }

  const dlsEntitlements = parsedToken['DLS-Entitlements'];
  if (!dlsEntitlements) {
    console.error('DLS-Entitlements not found in token');
    return { role: '', siteCode: '' }; // Default values if entitlements are missing
  }

  let role = '';
  let siteCode = ''; // Default value

  const entitlements = JSON.parse(dlsEntitlements);
  const roleAssignments = entitlements.RoleAssignments || [];

  const carePlusRoles = roleAssignments
    .filter((assignment) => assignment.EntityName === 'CarePlus')
    .map((assignment) => {
      const dataAttributes = assignment.DataAttributes || [];
      dataAttributes.forEach((attribute) => {
        if (attribute.AttributeName === 'SiteCode') {
          siteCode = attribute.AttributeValues[0] || '0';
        }
      });
      return assignment.RoleName;
    });

  if (carePlusRoles.length > 0) {
    role = carePlusRoles[0];
  }

  const impersonateData = getImpersonateData();
  if (impersonateData && impersonateData.isDealerImpersonate) {
    role = impersonateData.role;
    siteCode = impersonateData.siteCode;
  }

  return { role, siteCode };
};