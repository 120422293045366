import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Typography,
} from "@mui/material";
import IssueDetailHeader from "./IssueDetailHeader/IssueDetailHeader";
import CustomTabPanel from '../../components/CustomTabPanel';
import IssueDetailProfile from "./IssueDetailProfile/IssueDetailProfile";
import IssueDetailService from "./IssueDetailService/IssueDetailService";
import { useRole } from '../../context/RoleContext';
import { hasPageAccess, hasActionAccess } from '../../utils/permissions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//styles
import { styles } from "./DrawerStyles.style";
import AlertDetailHistory from "./AlertsDetailHistory/AlertsDetailHistory";

const IssuesDrawer = ({
  state,
  issueDetail,
  handleDrawerClose,
  handleStatusChange,
  setState,
  reloadData,
  getAllJobStatus,
  setCustomers,
  isCustomers,
}) => {
  const [value, setValue] = React.useState(1);
  const { roleConfig } = useRole();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const hasProfileVehiclePermission = hasActionAccess(roleConfig, 'alert_detail', 'profile_vehicle');
  const hasProfileFordPassPermission = hasActionAccess(roleConfig, 'alert_detail', 'profile_ford_pass');
  const hasProfileServicePermission = hasActionAccess(roleConfig, 'alert_detail', 'service_history');
  const hasAlertHistoryPermission = hasActionAccess(roleConfig, 'alert_detail', 'active_alerts');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (state.drawerOpen) {
      // Reset to 'Services' tab when the drawer is opened
      setValue(1);
    }
  }, [state.drawerOpen]);

  if (!hasPageAccess(roleConfig, 'alert_detail', 'read')) {
    return (
      <Drawer
        anchor="right"
        open={state?.drawerOpen}
        onClose={handleDrawerClose}
        data-testid="issues-drawer"
      >
        <Box sx={{ width: isMobile ? '100vw' : 800, padding: 2 }}> {/* Adjust width for mobile */}
          <Typography variant="h6">Permission Denied</Typography>
        </Box>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="right"
      open={state?.drawerOpen}
      onClose={handleDrawerClose}
      data-testid="issues-drawer"
    >
      {state?.isIssuesLoaded ? (
        <Box sx={{ width: isMobile ? '100vw' : 800, marginTop: "10px" }} role="presentation"> {/* Adjust width for mobile */}
          <IssueDetailHeader headingStyle={styles.subHeading} state={state} issue={issueDetail} handleDrawerClose={handleDrawerClose} />
          {(hasProfileVehiclePermission && hasProfileServicePermission && hasAlertHistoryPermission) && (
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs TabIndicatorProps={{ sx: { backgroundColor: "#0562D2", border: "2px solid #0562D2" } }} sx={{ marginLeft: "40px" }} value={value} onChange={handleChange} aria-label="basic tabs example">
                  {(hasProfileVehiclePermission || hasProfileFordPassPermission || hasAlertHistoryPermission) && (
                    <Tab iconPosition="start" sx={styles.tabHighlight} label="Profile" />
                  )}
                  {(hasProfileServicePermission) && (
                    <Tab iconPosition="start" sx={styles.tabHighlight} label="Active Alerts" />
                  )}
                  {(hasProfileServicePermission) && (
                    <Tab iconPosition="start" sx={styles.tabHighlight} label="Alert History" />
                  )}
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <IssueDetailProfile state={state} issue={issueDetail} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <IssueDetailService
                  handleStatusChange={handleStatusChange}
                  state={state}
                  issue={issueDetail}
                  setState={setState}
                  reloadData={reloadData}
                  getAllJobStatus={getAllJobStatus}
                  setCustomers={setCustomers}
                  isCustomers={isCustomers}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <AlertDetailHistory
                  handleStatusChange={handleStatusChange}
                  state={state}
                  issue={issueDetail}
                  setState={setState}
                  reloadData={reloadData}
                  getAllJobStatus={getAllJobStatus}
                  setCustomers={setCustomers}
                  isCustomers={isCustomers}
                />
              </CustomTabPanel>
            </Box>
          )}
          {((hasProfileVehiclePermission && !hasProfileServicePermission && !hasAlertHistoryPermission) && (
            /* istanbul ignore next */
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs TabIndicatorProps={{ sx: { backgroundColor: "#0562D2", border: "2px solid #0562D2" } }} sx={{ marginLeft: "40px" }} value={0} onChange={handleChange} aria-label="basic tabs example">
                  <Tab iconPosition="start" sx={styles.tabHighlight} label="Profile" />
                </Tabs>
              </Box>

              <CustomTabPanel value={0} index={0}>
                <IssueDetailProfile state={state} issue={issueDetail} />
              </CustomTabPanel>
            </Box>
          ))}
          {(!hasProfileVehiclePermission && hasProfileServicePermission && !hasAlertHistoryPermission) && (
            /* istanbul ignore next */
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs TabIndicatorProps={{ sx: { backgroundColor: "#0562D2", border: "2px solid #0562D2" } }} sx={{ marginLeft: "40px" }} value={0} onChange={handleChange} aria-label="basic tabs example">
                  {(hasProfileServicePermission) && (
                    <Tab iconPosition="start" sx={styles.tabHighlight} label="Active Alerts" />
                  )}
                </Tabs>
              </Box>
              <CustomTabPanel value={0} index={0}>
                <IssueDetailService
                  handleStatusChange={handleStatusChange}
                  state={state}
                  issue={issueDetail}
                  setState={setState}
                  reloadData={reloadData}
                  getAllJobStatus={getAllJobStatus}
                  isCustomers={isCustomers}
                />
              </CustomTabPanel>
            </Box>
          )}
          {(!hasProfileVehiclePermission && !hasProfileServicePermission && hasAlertHistoryPermission) && (
            /* istanbul ignore next */
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs TabIndicatorProps={{ sx: { backgroundColor: "#0562D2", border: "2px solid #0562D2" } }} sx={{ marginLeft: "40px" }} value={0} onChange={handleChange} aria-label="basic tabs example">
                  {(hasAlertHistoryPermission) && (
                    <Tab iconPosition="start" sx={styles.tabHighlight} label="Alert History" />
                  )}
                </Tabs>
              </Box>
              <CustomTabPanel value={0} index={0}>
                <AlertDetailHistory
                  handleStatusChange={handleStatusChange}
                  state={state}
                  issue={issueDetail}
                  setState={setState}
                  reloadData={reloadData}
                  getAllJobStatus={getAllJobStatus}
                  setCustomers={setCustomers}
                  isCustomers={isCustomers}
                />
              </CustomTabPanel>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: isMobile ? '100vw' : 850 }}> {/* Adjust width for mobile */}
          <CircularProgress />
        </Box>
      )}
    </Drawer>
  );
};

IssuesDrawer.propTypes = {
  state: PropTypes.object,
  handleDrawerClose: PropTypes.func,
  handleStatusChange: PropTypes.func,
  issueDetail: PropTypes.object,
  reloadData: PropTypes.func,
};


export default IssuesDrawer;