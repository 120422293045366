import * as XLSX from 'xlsx';

const getCurrentTimestamp = () => {
  const now = new Date();
  const padToTwoDigits = (num) => num.toString().padStart(2, '0');
  return `${padToTwoDigits(now.getMonth() + 1)}${padToTwoDigits(now.getDate())}${now.getFullYear()}_${padToTwoDigits(now.getHours())}${padToTwoDigits(now.getMinutes())}${padToTwoDigits(now.getSeconds())}`;
};

const exportToExcel = (formattedIssues, fileName = 'AlertList') => {
  const timestamp = getCurrentTimestamp();
  const worksheet = XLSX.utils.json_to_sheet(formattedIssues);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Alert');
  const finalFileName = `${fileName}_${timestamp}.xlsx`;
  XLSX.writeFile(workbook, finalFileName);
};

export { exportToExcel };