// src/services/apiEndpoints.js
const apiEndpoints = {
  ISSUELIST: '/leads',
  SEARCHVIN: '/vins',
  FILTERVALUES: '/distinctFilterLeadsValues',
  UPDATEISSUESSTATUS: '/alert',
  ISSUES: '/alertDetails',
  CUSTOMERDETAIL: '/customer/detail',
  ROLECONFIG: '/roleConfig',
  JOBLIST: '/jobs',
  ISSUELISTBYJOB: '/issuesByJobId',
  GETALLJOBSTATUS: '/getAllStatus',
  UPDATEJOBSSTATUS: 'updateJobStatus',
  JOBS: '/jobDetailInfo',
  EXPORT: '/alertsExport',
  METRICS: '/metrics',
  DISTINCTROLES: '/distinctRoles',
  CUSTOMERLIST: '/customer/alerts',
  IMPERSONATE: '/distinctRoles',
  LOGUSERACTIVITY: '/logUserActivity',
  CUSTOMERFILTERVALUES: '/customer/distinctFilterValues',
  CUSTOMERSEARCH: '/customer/vins',
  CUSTOMEREXPORT: '/customer/export',
  LISTSERVICENOTES: '/notes/getByVehicleProfileId',
  CREATESERVICENOTE: '/notes/createAll',
  EDITSERVICENOTE: 'notes/edit',
  DELETESERVICENOTE: 'notes/delete',
  GETCUSTOMERNOTE: '/notes/customerNotes',
  UPDATECUSTOMERNOTE: '/notes/customerNotes/update'
};

export default apiEndpoints;
