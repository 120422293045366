import { useState, useEffect } from "react";
import { hasActionAccess } from "../utils/permissions";
import issueService from "../services/issueService";

const useAlertStatus = (
    issue,
    roleConfig,
    reloadData,
    setCustomers,
    isCustomers,
    state,
    setState,
) => {
    const [selectedAlertStatuses, setSelectedAlertStatuses] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        if (issue?.alerts) {
          const statuses = issue.alerts.reduce((acc, alert) => ({
            ...acc,
            [alert.id]: alert.status
          }), {});
          setSelectedAlertStatuses(statuses);
        }
      }, [issue?.alerts]);

      const updateAlertStatus = (alertId, newStatus, alerts) => {
        return alerts.map((alert) =>
          alert.id === alertId ? { ...alert, status: newStatus } : alert
        );
      };
    
      const updateCustomers = (alertId, newStatus, prevCustomers) => {
        return prevCustomers.map((customer) => 
          {
          return({
            ...customer,
            alerts: updateAlertStatus(alertId, newStatus, customer.alerts),
          })
        });
      };
    
      const updateIssues = (alertId, newStatus, issues) => {
        return issues.map((job) => ({
          ...job,
          alerts: job.alerts ? updateAlertStatus(alertId, newStatus, job.alerts) : [],
        }));
      };


      const handleStatusChange = async (event, alertId, vin, originalStatus, alertTitle) => {
        if (!hasActionAccess(roleConfig, 'alert_detail', 'status_update')) {
          alert('Permission Denied');
          return;
        }
        try {
          const newStatus = event.value;
          setSelectedAlertStatuses((prev) => ({
            ...prev,
            [alertId]: newStatus,
          }));
    
          await issueService.updateStatusValue(alertId, newStatus);
          const isSpecialStatus =
          (["Completed", "Cancelled"].includes(newStatus) && !["Completed", "Cancelled"].includes(originalStatus)) ||
          (["Completed", "Cancelled"].includes(originalStatus) && !["Completed", "Cancelled"].includes(newStatus));
          const autoHideDuration = isSpecialStatus ? null : 3000;
          const statusUpdateText = isSpecialStatus ? `${alertTitle} Alert Status changed from <strong>${originalStatus}</strong> to <strong>${newStatus}</strong> successfully` : `Status changed from <strong>${originalStatus}</strong> to <strong>${newStatus}</strong> successfully`;

    
          setSnackbarMessage('Status updated successfully');
          setSnackbarOpen(true);
    
          if (isCustomers) {
            setCustomers((prevCustomers) => updateCustomers(alertId, newStatus, prevCustomers));
            reloadData(alertId, vin);
            if (newStatus) {
              setState((prev) => ({
                ...prev,
                isIssuesLoaded: false,
              }));
              setTimeout(() => {
                reloadData(alertId, vin);
                setState((prev) => ({
                  ...prev,
                  isIssuesLoaded: true,
                  statusUpdatePopUp: true,
                  autoHideStatus: autoHideDuration,
                  originalStatus: newStatus,
                  snackbarMessage: statusUpdateText,

                }));
              }, 2000);
            }
          }
    
          if (!isCustomers) {
            const updatedIssues = updateIssues(alertId, newStatus, state?.issues);
            setState((prev) => ({
              ...prev,
              issues: updatedIssues,
              statusUpdatePopUp: true,
              statusUpdatedData: updatedIssues.find((job) =>
                job.alerts?.some((alert) => alert.id === alertId)
              ),
              issueId: null,
            }));
            reloadData(alertId);
            if (newStatus) {
              setState((prev) => ({
                ...prev,
                isIssuesLoaded: false,
              }));
              setTimeout(() => {
                reloadData(alertId);
                setState((prev) => ({
                  ...prev,
                  isIssuesLoaded: true,
                }));
              }, 2000);
            }
          }
        } catch (error) {
          console.error(error);
          setSnackbarMessage('Failed to update status');
          setSnackbarOpen(true);
        }
      };

        return {
            selectedAlertStatuses,
            snackbarOpen,
            snackbarMessage,
            handleStatusChange,
            setSnackbarOpen
        };
}

export {
    useAlertStatus
}