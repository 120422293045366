import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoginWithAdfs, getUniqueName, getUserName } from '../../config/AuthenticatedRoute';
import { isTokenValid } from '../../utils/auth';
import {getRoleAndSiteCode} from '../../utils/roleUtils';
import Loader from '../../components/Loader';
import { Identify, identify, logEvent, setUserId } from '@amplitude/analytics-browser'; // Import Identify along with identify and logEvent

function LoginPage() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const valid = await isTokenValid();
      if (valid) {
        const username = getUniqueName(); // Get the unique username from the token
        const name = getUserName(); 
        const { role, siteCode } = getRoleAndSiteCode(); // Get the role and site code
        setUserId(username);
        // Create an Identify object and set user properties
        const identifyObj = new Identify();
        identifyObj.set('username', username); // You can add more properties if needed
        identifyObj.set('name', name);
        identifyObj.set('role', role);
        identifyObj.set('siteCode', siteCode);

        // Identify the user in Amplitude
        identify(identifyObj);

        // Log the login event
        logEvent('User Logged In', {
          user: username,
          name: name,
          role: role,
          siteCode: siteCode,
        });
        
        if (!siteCode) {
          navigate('/ImpersonateRole');
        } else {
          navigate('/');
        }
      } else {
        setLoading(false);
      }
    };

    validateToken();
  }, [navigate]);

  const handleLogin = () => {
    LoginWithAdfs();
  };

  if (loading) {
    return <Loader />; // Or any loading spinner
  }

  return (
    <Box style={{padding: "none"}} component="main" sx={styles.container}>
      <Grid justifyContent="center" alignItems="center" sx={styles.gridContainer}>
        <Grid item xs={12} sm={8} md={6} lg={5}>
          <Box sx={styles.card}>
            <Box component="img" src="/FordCarePluslogo.png" alt="logo" sx={styles.logo} />
            <Typography component="h1" variant="h5" sx={styles.title}>
              MANAGED CARE DASHBOARD
            </Typography>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleLogin}
            sx={styles.button}
          >
            LOGIN WITH US
          </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

/** @type {import("@mui/material").SxProps} */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    bgcolor: '#00095B', // Dark blue background
    width: '100%',
  },
  gridContainer: {
    width: "480px",
    height: '100vh',
  },
  card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      bgcolor: '#0078D4', // Light blue background
      padding: 4,
      borderRadius: '0 0 120px 120px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      width: '100%',
      maxWidth: '100%',
      height: '80vh'
  },
  logo: {
    width: '100%',
    maxWidth: '320px',
    marginBottom: 3,
  },
  title: {
    marginBottom: 3,
    color: '#00095B',
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '3rem',
  },
  button: {
    marginTop: 5,
      bgcolor: '#FFFFFF',
      color: '#000000',
      fontWeight: 'bold',
      fontSize: '1.5rem',
      borderRadius: '25px',
      '&:hover': {
        bgcolor: '#E6E6E6', // Light grey on hover
      },
      width: '310px',
      textAlign: 'center',
  },
};

export default LoginPage;