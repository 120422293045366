// src/services/issueService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';
import { getRoleAndSiteCode } from '../utils/roleUtils';
import { getUniqueName, getUserName } from '../config/AuthenticatedRoute';

const getIssues = async (page, isFilter, filters, pageSize, sortCriteria, defaultSort) => {
  const { role, siteCode } = getRoleAndSiteCode();
  // Check for impersonate data
  const payload = {
    paginationParams: {
      page,
      pageSize,
      filterRequest: {
        filterParams: filters,
        filter: isFilter
      },
      sortingParams: {
        defaultSort,
        sortingKey: sortCriteria
      }
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const response = await axiosInstance.post(apiEndpoints.ISSUELIST, payload);
  return response.data;
};

const getSearchVins = async (isFilter, filters, searchInput) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    searchParam: searchInput,
    filterRequest: {
      filterParams: filters,
      filter: isFilter
    },
    appUserDetails: {
      role,
      siteCode
    }
  };
  const response = await axiosInstance.post(apiEndpoints.SEARCHVIN, payload);
  return response.data;
};

const getExportData = async (page, pageSize, isFilter, filters, sortCriteria, defaultSort) => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    paginationParams: {
      page: page,
      pageSize: pageSize,
      filterRequest: {
        filterParams: filters,
        filter: isFilter
      },
      sortingParams: {
        defaultSort,
        sortingKey: sortCriteria
      }
    },
    appUserDetails: {
      role: role,
      siteCode: siteCode
    }
  };

  const response = await axiosInstance.post(apiEndpoints.EXPORT, payload);
  return response.data;
};

const getFilterValues = async () => {
  const { role, siteCode } = getRoleAndSiteCode();

  const payload = {
    role,
    siteCode
  };
  const response = await axiosInstance.post(apiEndpoints.FILTERVALUES, payload);
  return response.data;
};

const updateStatusValue = async (id, newStatus) => {
  const username = getUniqueName();
  const payload = {
    status: newStatus,
    updatedBy: username
  };
  const response = await axiosInstance.post(`${apiEndpoints.UPDATEISSUESSTATUS}/${id}`, payload);
  return response.data;
};

const getIssueDetail = async (id) => {
  const response = await axiosInstance.post(`${apiEndpoints.ISSUES}/${id}`);
  return response.data;
};

const getAllMetrics = async (siteCodes) => {
  const payload = {
    siteCodes
  };
  const response = await axiosInstance.post(`${apiEndpoints.METRICS}/getAll`, payload);
  return response.data;
};

const getAllSiteCodes = async () => {
  const response = await axiosInstance.get(`${apiEndpoints.DISTINCTROLES}`);
  return response.data;
};

// New function to create notes
const createNote = async (noteData) => {
  const username = getUserName();
  noteData.createdBy = username;
  const response = await axiosInstance.post(`${apiEndpoints.CREATESERVICENOTE}`, noteData);
  return response.data;
};

// New function to get notes by vehicle profile ID
const getNotesByVehicleProfileId = async (vehicleProfileId) => {
  const response = await axiosInstance.post(`${apiEndpoints.LISTSERVICENOTES}/${vehicleProfileId}`);
  return response.data;
};

const editNote = async (noteId, content) => {
  const username = getUserName();
  const payload = {
    noteId,
    content,
    updatedBy: username
  };
  const response = await axiosInstance.post(`${apiEndpoints.EDITSERVICENOTE}`, payload);
  return response.data;
};

const deleteNote = async (noteId) => {
  const username = getUserName();
  const payload = {
    noteId,
    deletedBy: username
  };
  const response = await axiosInstance.post(`${apiEndpoints.DELETESERVICENOTE}`, payload);
  return response.data;
};

const getCustomerNote = async (vehicleProfileId) => {
  const response = await axiosInstance.post(`${apiEndpoints.GETCUSTOMERNOTE}/${vehicleProfileId}`);
  return response.data;
};

const updateCustomerNote = async (noteData) => {
  const username = getUserName();
  noteData.updatedBy = username;
  const response = await axiosInstance.post(`${apiEndpoints.UPDATECUSTOMERNOTE}`, noteData);
  return response.data;
};

const issueService = {
  getIssues,
  getSearchVins,
  getFilterValues,
  updateStatusValue,
  getIssueDetail,
  getExportData,
  getAllMetrics,
  getAllSiteCodes,
  createNote,
  getNotesByVehicleProfileId,
  editNote,
  deleteNote,
  getCustomerNote,
  updateCustomerNote
};

export default issueService;
